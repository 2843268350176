import React from 'react';
//import { Link } from 'react-router-dom';
import styled from 'styled-components'; // package to define css class
//import $ from 'jquery'; // package to run jQuery
//import { Button } from 'bootstrap';
//import appstoreQRCode from '../images/qrcode/appstoreQRCode.png';
//import googleplayQRCode from '../images/qrcode/androidQRCode.png';
import Popup from 'reactjs-popup';
import './PopupModal.css';

const Col6 = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: middle;
`;

function PopupModal({ Ref }) {
    function toDownloadAppIOS() {
        window.open('https://apps.apple.com/hk/app/vivablee/id1551773278', "_blank");
        //window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
    }
    function toDownloadAppAndroid() {
        window.open('https://play.google.com/store/apps/details?id=com.vivableeraw', "_blank");
        //window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
    }
    return (
        <div>
            <Popup
                ref={Ref}
                modal
                nested
                repositionOnResize
                contentStyle={{ maxWidth: '1000px', borderRadius: '25px' }}
            >
                {close => (
                    <div className="modal">
                        <button className="close" onClick={close}>
                            &times;
                            </button>
                        {/* <div className="header"> Download Vivablee </div> */}
                        <Col6>
                            {/* <h1><center>App Store</center></h1> */}
                            <img
                                style={{ width: '70%' }}
                                src={require('../images/qrcode/appstoreQRCode.png')}
                                className="center"
                                alt="appstore qrcode"
                            ></img>
                            <img
                                onClick={toDownloadAppIOS}
                                style={{ width: '60%', cursor: 'pointer' }}
                                //style={{ width: '170px', cursor: 'pointer' }}
                                src={require('../images/general/app_store.png')}
                                className="center"
                                alt="appstore download"
                            ></img>
                        </Col6>
                        <Col6>
                            {/* <h1><center>Google Play</center></h1> */}
                            <img
                                style={{ width: '70%' }}
                                src={require('../images/qrcode/androidQRCode.png')}
                                className="center"
                                alt="googleplay qrcode"
                            ></img>
                            <img
                                onClick={toDownloadAppAndroid}
                                style={{ width: '60%', cursor: 'pointer' }}
                                //style={{ width: '170px', cursor: 'pointer' }}
                                src={require('../images/general/google_play.png')}
                                className="center"
                                alt="googleplay download"
                            ></img>
                        </Col6>
                    </div>
                )}
            </Popup>
        </div>
    );
}

export default PopupModal;