import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
//import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
//import * as HtmlToReact from 'html-to-react';
//import { storage, db } from '../firebase';
//import Skeleton from 'react-loading-skeleton';
//import Skeleton from '@material-ui/lab/Skeleton';
import Skeleton from '@material-ui/lab/Skeleton';
//import { Helmet } from "react-helmet";
import './Article.css';
//import { borderRadius } from 'react-select/src/theme';

function ArticleBanner({ defaultBanner, articlesList, loading }) {
  /*
  const ArticleBannerImage = styled.img`
    cursor: pointer;
    border-radius: 30px;
    width: 70%;
    margin-left: 14%;
    margin-right: 14%;
    margin-top: 100px;
  `;
  */
  const ArticleBannerTable = styled.table`
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 30px;
  width: 70%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
  const BannerBtn3 = styled.button`
    padding: 5px 17px 6px 17px;
    color: white;
    border-radius: 50px;
    border-style: none;
    background-color: #ff9700;
    font-weight: 700;
    font-size: 16px;
    box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    margin-left: 2%;
    margin-top: 3px;
`;
  const ArticleCardDiv = styled.div`
    width: 70%;
    height: 400px;
    margin-left: 14%;
    background-position: center;
    border-radius: 15px;
    margin-right: 14%;
    margin-bottom: 10px;
    margin-top: 20px;
  @media only screen and (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    width: 96%;
  }
`;
  const ArticleCardDivGrey = styled.div`
  width: 70%;
  height: 400px;
  border-radius: 15px;
  margin-left: 14%;
  margin-right: 14%;
  margin-bottom: 10px;
  margin-top: -410px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    width: 96%;
  }
`;
  const ArticleBannerTable768 = styled.table`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    width: 96%;
    @media only screen and (min-width: 769px) {
      display: none;
    }
`;
  const ArticleBannerTableText = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `;
  /*
  const ArticleBannerCarousel = styled.div`
    @media only screen and (min-width: 901px) {
      display: none;
    }
  `;
  */
  const ArticleBannerIconDiv = styled.div`
    width: 70%;
    height: 0%;
    @media only screen and (max-width: 1200px) {
      width: 60%;
      height: 0%;
    }
    @media only screen and (max-width: 768px) {
      width: 80%;
      height: 0%;
    }
    @media only screen and (max-width: 400px) {
      width: 90%;
    }
  `;

  const ArticleBannerSmallIconDiv = styled.div`
    width: 70%;
    height: 400px;
    position:absolute;
    @media only screen and (max-width: 768px) {
      width: calc(100% - 20px);
    }
  `;

  const ArticleBannerBox = styled.div`
    width: 50%;
    @media only screen and (max-width: 1200px) {
      width: 60%;
    }
    @media only screen and (max-width: 768px) {
      width: 80%;
    }
    @media only screen and (max-width: 400px) {
      width: 90%;
    }
  `;

  const ArticleBannerSkeletonDiv = styled.div`
    margin-left: 14%;
    margin-right: 14%;
    @media only screen and (max-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
      width: 96%;
    }
  `;

  function comingSoon() {
    alert('Coming soon!');
  }

  const [banner_id, setBanner_id] = useState(defaultBanner);

  function clickToBanner(id) {
    setBanner_id(id);
    $('html,body').animate({ scrollTop: $("#article_" + id).offset() }, 'slow');
    return false;
  }
  return (
    < div >
      <div style={{ height: '20px' }} />
      {
        <>
          {
            loading ?
              <div>
                <ArticleBannerSkeletonDiv>
                  <Skeleton
                    height={'670px'} animation="wave"
                    style={{
                      borderRadius: 30, width: '100%',
                      marginTop: '-142px'
                    }}
                  />
                </ArticleBannerSkeletonDiv>
                <div style={{ marginTop: '-100px' }} />
              </div>
              :
              <div>
                {articlesList.slice(0, articlesList.length)
                  .map((item, i) => {
                    return (
                      (i + 1 === banner_id) ?
                        <Link
                          to={{
                            pathname: "/Articles/" + item.article_title
                          }}
                          style={{ textDecoration: 'none', color: 'black' }}
                          key={i}
                        >{
                            <div>
                              <ArticleCardDiv style={{ background: `url(${item.article_image}) no-repeat center` }}>
                                <div style={{ position: 'absolute', height: 400, width: 'calc(100% - 40px)' }}>
                                  {
                                    <ArticleBannerBox
                                      style={{
                                        borderRadius: '10px',
                                        backgroundColor: 'white',
                                        zIndex: '3',
                                        position: 'absolute',
                                        display: 'inline-block',
                                        alignSelf: 'flex-end',
                                        padding: '5px 10px',
                                        bottom: 20,
                                        left: 20,
                                      }}
                                    >
                                      <div style={{ padding: '15px 20px 5px 0px', marginLeft: '2%' }}>
                                        <p style={{ color: '#333', fontSize: '20px', fontWeight: '700' }}>
                                          {item.article_title}
                                        </p>
                                      </div>
                                      <BannerBtn3 style={{ marginBottom: '15px' }}>
                                        <p>繼續閲讀</p>
                                      </BannerBtn3>
                                    </ArticleBannerBox>
                                  }
                                </div>
                              </ArticleCardDiv>

                              <ArticleCardDivGrey>
                                <ArticleBannerIconDiv>
                                  <ArticleBannerSmallIconDiv>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        right: '20px',
                                        backgroundColor: '#FF9700',
                                        padding: '2px 8px 4px 8px',
                                        borderRadius: '50px',
                                        marginTop: '20px',
                                      }}
                                    >
                                      <img
                                        style={{ width: '12px', marginBottom: '-2px' }}
                                        src={require('../images/general/chillful_icon_white.png')}
                                        alt="icon"
                                      ></img>
                                      <span
                                        style={{
                                          color: 'white',
                                          fontSize: '12px',
                                          fontWeight: '700',
                                          marginLeft: '4px',
                                        }}
                                      >
                                        {item.article_category}
                                      </span>
                                    </div>
                                  </ArticleBannerSmallIconDiv>
                                </ArticleBannerIconDiv>
                              </ArticleCardDivGrey>
                            </div>
                          }
                        </Link>
                        :
                        <div key={i} />
                    )
                  }
                  )
                }
              </div>
          }
          <ArticleBannerTable768>
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '11%' }} />
              <col style={{ width: '12%' }} />
              <col style={{ width: '11%' }} />
              <col style={{ width: '12%' }} />
              <col style={{ width: '11%' }} />
              <col style={{ width: '12%' }} />
              <col style={{ width: '11%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
            <tbody>
              <tr style={{ verticalAlign: 'top' }}>
                <td />
                <td style={{ justifyContent: 'center', }}>
                  <p
                    onClick={() => clickToBanner(1)}
                    style={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color: banner_id === 1 ? '#fff' : '#aaa',
                      backgroundColor: banner_id === 1 ? '#ff9700' : 'white',
                      borderRadius: 15,
                      padding: 5,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    1
                  </p>
                </td>
                <td />
                <td>
                  <p
                    onClick={() => clickToBanner(2)}
                    //class="toBannerButton"
                    style={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color: banner_id === 2 ? '#fff' : '#aaa',
                      backgroundColor: banner_id === 2 ? '#ff9700' : 'white',
                      borderRadius: 15,
                      padding: 5,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    2
                  </p>
                </td>
                <td />
                <td>
                  <p
                    onClick={() => clickToBanner(3)}
                    //class="toBannerButton"
                    style={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color: banner_id === 3 ? '#fff' : '#aaa',
                      backgroundColor: banner_id === 3 ? '#ff9700' : 'white',
                      borderRadius: 15,
                      padding: 5,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    3
                  </p>
                </td>
                <td />
                <td>
                  <p
                    onClick={() => clickToBanner(4)}
                    //class="toBannerButton"
                    style={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color: banner_id === 4 ? '#fff' : '#aaa',
                      backgroundColor: banner_id === 4 ? '#ff9700' : 'white',
                      borderRadius: 15,
                      padding: 5,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    4
                  </p>
                </td>
                <td />
              </tr>
            </tbody>
          </ArticleBannerTable768>

          <ArticleBannerTable>
            <colgroup>
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
            </colgroup>

            <tbody>
              {
                loading ?
                  <tr>
                    <td>
                      <Skeleton width={'90%'} height={100} animation="wave" style={{ marginTop: '-38px', marginBottom: '-10px' }} />
                    </td>
                    <td>
                      <Skeleton width={'90%'} height={100} animation="wave" style={{ marginTop: '-38px', marginBottom: '-10px' }} />
                    </td>
                    <td>
                      <Skeleton width={'90%'} height={100} animation="wave" style={{ marginTop: '-38px', marginBottom: '-10px' }} />
                    </td>
                    <td>
                      <Skeleton width={'90%'} height={100} animation="wave" style={{ marginTop: '-38px', marginBottom: '-10px' }} />
                    </td>
                  </tr>
                  :
                  <tr>
                    {articlesList.slice(0, articlesList.length)
                      .map((item, i) => {
                        if (i < 4)
                          return (
                            <td style={{ verticalAlign: 'top' }} key={i}>
                              {
                                <div
                                  onClick={() => clickToBanner(i + 1)}
                                  style={{
                                    fontSize: '16px',
                                    color: banner_id === (i + 1) ? '#444' : '#aaa',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <table style={{ marginRight: 30 }}>
                                    <colgroup>
                                      <col style={{ width: '13%' }} />
                                      <col style={{ width: '87%' }} />
                                    </colgroup>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: 32, verticalAlign: 'top' }}>{i + 1} - </td>
                                        {
                                          <td>
                                            <ArticleBannerTableText>
                                              {item.article_title}
                                            </ArticleBannerTableText>
                                          </td>
                                        }
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              }
                            </td>
                          )
                      })}
                  </tr>
              }

              <tr>
                <td>
                  <div
                    onClick={comingSoon}
                    style={{
                      width: '90%',
                      height: '2px',
                      backgroundColor: banner_id === 1 ? '#444' : '#aaa',
                      cursor: 'pointer',
                    }}
                  ></div>
                </td>
                <td>
                  <div
                    onClick={comingSoon}
                    style={{
                      width: '90%',
                      height: '2px',
                      backgroundColor: banner_id === 2 ? '#444' : '#aaa',
                      cursor: 'pointer',
                    }}
                  ></div>
                </td>
                <td>
                  <div
                    onClick={comingSoon}
                    style={{
                      width: '90%',
                      height: '2px',
                      backgroundColor: banner_id === 3 ? '#444' : '#aaa',
                      cursor: 'pointer',
                    }}
                  ></div>
                </td>
                <td>
                  <div
                    onClick={comingSoon}
                    style={{
                      width: '90%',
                      height: '2px',
                      backgroundColor: banner_id === 4 ? '#444' : '#aaa',
                      cursor: 'pointer',
                    }}
                  ></div>
                </td>
              </tr>
            </tbody>
          </ArticleBannerTable>
        </>
      }
    </div >
  );
}

export default ArticleBanner;
