import React, { useEffect } from 'react';

import { isAndroid } from 'react-device-detect';

function Downloads() {
  useEffect(() => {
    if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
    } else {
      window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
    }
  });

  return <div style={{ width: '100%' }}></div>;
}

export default Downloads;
