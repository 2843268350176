import React, { useState, useEffect } from 'react';
//import {Link} from 'react-router-dom';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { db } from '../firebase.js';
import img from '../images/chillful/event_1.jpg';

// define css class
/*
const ChillfulSearchBoxFrame = styled.div`
  position: absolute;
  width: 100%;
`;
const ChillfulSearchBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  margin-top: -70px;
  border-radius: 10px;
  position: absolute;
  right: 50%;
  @media only screen and (max-width: 740px) {
    width: calc(100vw - 40px);
  }
`;
const ChillfulSearchBoxInner = styled.div`
  border-radius: 10px;
  background-color: white;
  position: relative;
  right: -50%;
  padding: 20px 30px;
  box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.05);
`;
const ChillfulSearchBoxBtn = styled.div`
  border: 2px solid #333;
  border-radius: 100px;
  padding: 3px 5px 3px 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  margin-right: 3px;
`;
const ChillfulSearchBoxBtn2 = styled.div`
  border: 2px solid #333;
  border-radius: 100px;
  padding: 3px 5px 3px 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  margin-right: 3px;
  @media only screen and (max-width: 540px) {
    display: none;
  }
`;
const ChillfulSearchBoxBtn3 = styled.div`
  border: 2px solid #333;
  border-radius: 100px;
  padding: 3px 5px 3px 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  margin-right: 3px;
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;
const ChillfulSearchBoxBtn4 = styled.div`
  border: 2px solid #333;
  border-radius: 100px;
  padding: 3px 5px 3px 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  margin-right: 3px;
  @media only screen and (max-width: 740px) {
    display: none;
  }
`;
const ChillfulSearchBoxBtnText = styled.div`
  cursor: pointer;
  display: none;
  font-size: 14px;
  margin-left: 10px;
  color: #ff585d;
  text-decoration: underline;
  font-weight: 600;
  @media only screen and (max-width: 740px) {
    display: inline-block;
  }
`;
const ChillfulMargin10 = styled.div`
  height: 10px;
`;
const ChillfulMargin40 = styled.div`
  height: 40px;
`;
const ChillfulSearchBtn = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ff9700;
  border-radius: 100px;
  float: right;
  margin-right: 10px;
  cursor: pointer;
`;
const ChillfulSearchIcon = styled.img`
  height: 22px;
  margin-top: 14px;
  margin-left: 14px;
`;
const ChillfulSearchInputBar = styled.input`
  width: 100%;
  border-style: none;
  font-size: 20px;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  :focus {
    outline: none;
  }
`;
const ChillfulSearchSearchBar = styled.div`
  padding: 10px 0px 10px 20px;
  border-radius: 50px;
  background-color: white;
  max-width: 800px;
  background-color: #f9f9f9;
`;
const ChillfulSearchIntro = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px;
`;
const ChillfulSearchIntroStrong = styled.span`
  font-weight: 700;
  color: #ff585d;
`;
const ChillfulSearchTable = styled.table`
  padding-left: 10px;
  width: 100%;
  border-collapse: separate;
`;
const ActivitySearchBoxPopUpDiv = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  background-color: #ff585d;
  opacity: 0;
  visibility: hide;
  transition: all 0.5s;
  z-index: -1;
  @media only screen and (max-width: 740px) {
    display: none;
  }
`;

const ActivitySearchBoxPopUpTable = styled.table`
  width: 100%;
  border-radius: 10px;
`;
const ActivitySearchBoxPopUpTd = styled.td`
  cursor: pointer;
`;
const ActivitySearchBoxPopUpCategoryTop = styled.div`
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
`;
const ActivitySearchBoxPopUpCategory = styled.div`
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
`;
const ActivitySearchBoxPopUpCategoryBottom = styled.div`
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
`;
const ActivitySearchBoxPopUpTdMargin10 = styled.td`
  width: 10px;
`;
const ActivitySearchBoxPopUpDivMargin10 = styled.div`
  width: 10px;
  background-color: white;
`;
const ActivitySearchBoxPopUpPointerTd = styled.td`
  background-color: white;
  width: 10px;
`;
const ActivitySearchBoxPopUpPointerImage = styled.img`
  height: 16px;
  width: 10px;
  display: none;
`;
const ActivitySearchBoxPopUpActivityTable = styled.table`
  display: none;
`;
const ActivitySearchBoxPopUpActivityTableDiv = styled.div`
  overflow-x: scroll;
  width: 540px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ActivitySearchBoxPopUpActivityTableTd = styled.td`
  background-color: white;
  width: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;
const ActivitySearchBoxPopUpColWidth50 = styled.col`
  width: 50%;
`;
*/
const ActivitySearchBoxPopUpActivityBox = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
  cursor: pointer;
  border-radius: 30px;
  width: 300px;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.1);
`;
/*
const ActivitySearchBoxPopUpActivityBox2 = styled.div`
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
  cursor: pointer;
  border-radius: 30px;
  width: 300px;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.1);
  width: 300px;
`;
*/
const ActivitySearchBoxPopUpActivityInner = styled.div`
  position: relative;
  width: 100%;
  padding-top: 50%;
`;
const ActivitySearchBoxPopUpActivityImageCover = styled.div`
  background-image: url(${img});
  background-size: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const ActivitySearchBoxPopUpActivityBottom = styled.div`
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: black;
`;

const ActivitySearchBoxPopUpActivityBottomIcon = styled.img`
  display: inline;
  width: 10px;
`;

const ActivitySearchBoxPopUpActivityBottomText1 = styled.p`
  display: inline;
  font-size: 12px;
  margin-left: 5px;
  color: #ff585d;
  font-weight: 700;
`;
const ActivitySearchBoxPopUpActivityBottomText2 = styled.p`
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ActivitySearchBoxPopUpActivityBottomText3 = styled.p`
  display: inline;
  font-size: 12px;
`;
/*
const ActivitySearchBoxPopUpCategoryMargin = styled.div`
  background-color: white;
  height: 1px;
  width: 140px;
`;
const ActivitySearchBoxPopUpCategoryMargin2 = styled.td`
  background-color: white;
`;
*/
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

$(document).click(async function (event) {
  const $target = $(event.target);
  if (
    !$target.closest('#searchInput').length &&
    !$target.closest('#searchPopup').length &&
    $('#searchPopup').is(':visible')
  ) {
    $('#searchPopup').css('opacity', '0');
    $('#searchPopup').css('visibility', 'hide');
    $('#searchPopup').css('marginTop', '20px');
    $('#searchPopup').css('zIndex', '-1');
    await sleep(500);
    hidePointer();
    hideTable();
    $('#popup_pointer_1').css('display', 'block');
    $('#popup_table_1').css('display', 'block');
  }
});

function hidePointer() {
  $('#popup_pointer_1').hide();
  $('#popup_pointer_2').hide();
  $('#popup_pointer_3').hide();
  $('#popup_pointer_4').hide();
  $('#popup_pointer_5').hide();
}
function hideTable() {
  $('#popup_table_1').hide();
  $('#popup_table_2').hide();
  $('#popup_table_3').hide();
  $('#popup_table_4').hide();
  $('#popup_table_5').hide();
}

function ActivitySearchBoxActivityCard({ id, activity_id }) {
  const [chillfulActivityTitle, setChillfulActivityTitle] = useState('');
  const [chillfulActivityDescription, setChillfulActivityDescription] = useState('');
  const [chillfulActivityCategory, setChillfulActivityCategory] = useState('');
  const [chillfulActivitySubCategory, setChillfulActivitySubCategory] = useState('');
  const [chillfulActivityImagesSmall, setChillfulActivityImagesSmall] = useState('');
  const [chillfulActivityImagesLarge, setChillfulActivityImagesLarge] = useState('');
  const [chillfulActivityCurrency, setChillfulActivityCurrency] = useState('');
  const [chillfulActivityPrice, setChillfulActivityPrice] = useState('');
  const [chillfulActivityDuration, setChillfulActivityDuration] = useState('');

  function comingSoon() {
    alert('Coming soon!');
  }

  useEffect(() => {
    // to get the information for activity
    db.collection('chillfulActivities')
      .doc(activity_id)
      .get()
      .then((doc) => {
        const data = doc.data();
        setChillfulActivityTitle(data['title']);
        setChillfulActivityDescription(data['description']);
        setChillfulActivityCategory(data['category']);
        setChillfulActivitySubCategory(data['subCategory']);
        setChillfulActivityImagesSmall(data['imagesSmall']);
        setChillfulActivityImagesLarge(data['imagesLarge']);
        setChillfulActivityCurrency(data['currency']);
        setChillfulActivityPrice(data['price']);
        setChillfulActivityDuration(data['duration'] / 3600);
      });
  }, []);

  // activity card content
  return (
    <ActivitySearchBoxPopUpActivityBox onClick={comingSoon}>
      <ActivitySearchBoxPopUpActivityInner>
        <ActivitySearchBoxPopUpActivityImageCover
          style={{ backgroundImage: 'url(' + chillfulActivityImagesSmall + ')' }}
        ></ActivitySearchBoxPopUpActivityImageCover>
      </ActivitySearchBoxPopUpActivityInner>
      <ActivitySearchBoxPopUpActivityBottom>
        <ActivitySearchBoxPopUpActivityBottomIcon
          src={require('../images/general/chillful_icon_original.png')}
        ></ActivitySearchBoxPopUpActivityBottomIcon>
        <ActivitySearchBoxPopUpActivityBottomText1>
          {chillfulActivityCategory}
        </ActivitySearchBoxPopUpActivityBottomText1>
        <ActivitySearchBoxPopUpActivityBottomText2>
          {chillfulActivityTitle}
        </ActivitySearchBoxPopUpActivityBottomText2>
        <ActivitySearchBoxPopUpActivityBottomText3>
          {chillfulActivitySubCategory} ● {chillfulActivityDuration} hours
        </ActivitySearchBoxPopUpActivityBottomText3>
      </ActivitySearchBoxPopUpActivityBottom>
    </ActivitySearchBoxPopUpActivityBox>
  );
}

export default ActivitySearchBoxActivityCard;
