import React, { useState, useEffect, useRef } from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import styled from 'styled-components'; // package to define css class
import { db } from '../firebase.js';
import { useTranslation } from 'react-i18next';
import './AtoZ.css';
import * as HtmlToReact from 'html-to-react';
import { useParams } from 'react-router-dom';
//import AtoZ from './AtoZ';
import { Helmet } from "react-helmet";
import url_icon from '../images/articles/url.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PopupModal from '../components/PopupModal';
//import { StepContent } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    FacebookShareButton,
    LineShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
    TwitterIcon,
    LineIcon,
} from "react-share";

const MenuButton = styled.button`
  padding: 10px 20px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

const AtoZPageDiv = styled.div`
    margin-left: auto;
    margin-rigth: auto;
    line-height: 30px;
    @media only screen and (max-width: 840px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;
/*
const AtoZFlexDiv = styled.div`
    margin-left: auto;
    margin-rigth: auto;
    display: flex;
    justify-content: center;
    align-items: center;    
`;
*/
/**
 *     
 *  display: flex;
    justify-content: center;
    align-items: center;
 */

const AtoZContentTable = styled.table`
    width: 800px;
@media only screen and (max-width: 840px) {
    display: none;
}  
`;
const AtoZContentTable768 = styled.table`
@media only screen and (min-width: 840px) {
    display: none;
}  
`;
const AtoZButtonDiv = styled.div`
    width: 800px;
`;

const AtoZTitle = styled.h1`
    line-height: 38px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
`;
/*
const AtozCardDiv = styled.div`
    width: 100%;
    background-position: center;
    border-radius: 15px;
`;
*/
function AtozPage() {

    //const [atozList, setAtozList] = useState([]);
    //const [optionList, setOptionList] = useState([]);
    const { page } = useParams();
    const [metaDescription, setMetaDescription] = useState("");
    const [keywords, setKeywords] = useState([]);
    const [content, setContent] = useState([]);
    //const htmlToReactParser = new HtmlToReact.Parser();
    //const parsecontent = htmlToReactParser.parse(content);
    const htmlToReactParser = new HtmlToReact.Parser();
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [layoutMode, setLayoutMode] = useState([]);
    const [atozImage, setAtozImage] = useState([]);
    //copy this for every new mode
    //const [loadingImage, setLoadingImage] = useState(true);
    const [loadingImage1, setLoadingImage1] = useState(true);
    const [loadingImage2, setLoadingImage2] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ref = useRef();
    const openTooltip = () => ref.current.open();

    function PreloadImage() {
        return (
            <div>
                {atozImage.map((item, i) =>
                    <link rel="preload" as="image" href={item} key={i}></link>
                )}
            </div>
        )
    }

    useEffect(() => {
        if (page !== "" && page !== undefined)
            db.collection("atoz")
                .where('title', '==', page)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.docs.forEach((doc) => {
                        setTitle(doc.data()['title_chi']);
                        setMetaDescription(doc.data()['metaDescription']);
                        setKeywords(doc.data()['keywords']);
                        setContent(doc.data()['content']);
                        setLayoutMode(doc.data()['mode']);
                        setAtozImage(doc.data()['image']);
                    });
                })
                .then(() => {
                    PreloadImage();
                })
                .then(() => {
                    setLoading(false);
                });
    }, []);

    const DefaultLayoutMode = ({ order }) => (
        <div>
            {content.slice(order, order + 1).map((item, i) =>
                <div key={i}>
                    {htmlToReactParser.parse(item)}
                </div>
            )}
            <br />
        </div>
    );

    const LayoutMode1 = ({ order }) => (
        <div>
            <AtoZContentTable>
                <colgroup>
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '65%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                            {atozImage.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    {loadingImage1 && <Skeleton height={500} width={"100%"} style={{ marginTop: "-120px", borderRadius: "15px" }} />}
                                    <link rel="preload" as="image" href={item}></link>
                                    <img src={item} alt="atoz" width="100%" style={{ borderRadius: 15 }} onLoad={() => { setLoadingImage1(false) }}></img>
                                </div>
                            )}
                        </td>
                        <td />
                        <td>
                            {content.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    {htmlToReactParser.parse(item)}
                                </div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </AtoZContentTable>
            <AtoZContentTable768>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                            {atozImage.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    <center>
                                        {loadingImage1 && <Skeleton height={500} width={"100%"} style={{ borderRadius: "15px", marginTop: "-120px", marginBottom: "-80px" }} />}
                                        <link rel="preload" as="image" href={item}></link>
                                        <img src={item} alt="atoz" width="100%" onLoad={() => { setLoadingImage1(false) }}
                                            style={{ borderRadius: 15, maxHeight: 300, width: 'calc(100%-40px)', marginBottom: 20, objectFit: 'none' }}></img>
                                    </center>
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {content.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    {htmlToReactParser.parse(item)}
                                </div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </AtoZContentTable768>
            <br />
        </div>
    );

    const LayoutMode2 = ({ order }) => (
        <div>
            <AtoZContentTable>
                <colgroup>
                    <col style={{ width: '65%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '30%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            {content.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    {htmlToReactParser.parse(item)}
                                </div>
                            )}
                        </td>
                        <td />
                        <td style={{ verticalAlign: 'top' }}>
                            {atozImage.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    {loadingImage2 && <Skeleton height={500} width={"100%"} style={{ borderRadius: "15px", marginTop: "-140px", marginBottom: "-80px" }} />}
                                    <link rel="preload" as="image" href={item}></link>
                                    <img src={item} alt="atoz" width="100%" style={{ borderRadius: 15 }} onLoad={() => { setLoadingImage2(false) }}></img>
                                </div>
                                // <AtozCardDiv style={{ backgroundImage: `url(${item}) no-repeat center` }} key={i} />
                            )}
                        </td>
                    </tr>
                </tbody>
            </AtoZContentTable>
            <AtoZContentTable768>
                <colgroup>
                    <col style={{ width: '100%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                            {atozImage.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    <center>
                                        {loadingImage2 && <Skeleton height={500} width={"100%"} style={{ borderRadius: "15px", marginTop: "-100px", marginBottom: "-80px" }} />}
                                        <link rel="preload" as="image" href={item}></link>
                                        <img src={item} alt="atoz" width="100%" onLoad={() => { setLoadingImage2(false) }}
                                            style={{ borderRadius: 15, maxHeight: 300, width: 'calc(100%-40px)', marginBottom: 20, objectFit: 'none' }}></img>
                                    </center>
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {content.slice(order, order + 1).map((item, i) =>
                                <div key={i}>
                                    {htmlToReactParser.parse(item)}
                                </div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </AtoZContentTable768>
            <br />
        </div >
    );

    // function switchMode({mode, i}) {
    //     switch (mode) {
    //         case 1:
    //             return <LayoutMode1 order={i} key={i} />;
    //         case 2:
    //             return <LayoutMode2 order={i} key={i} />;
    //         default:
    //             return <DefaultLayoutMode order={i} key={i} />;
    //     }
    // }

    return (
        <div>
            <Helmet>
                <title>{page}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={keywords} />
            </Helmet>
            <Menu page={'atozPage'} />
            {/* <AtoZFlexDiv> */}
            <AtoZPageDiv>
                {loading ?
                    <div style={{ width: "60%", marginLeft: "20%" }}>
                        <center>
                            <Skeleton width={'50%'} height={50} animation="wave" style={{ marginTop: "40px", marginBottom: "40px", marginLeft: '0%' }} />
                        </center>
                        <Skeleton width={'80%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'100%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'50%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'80%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'100%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'50%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'80%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'100%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'50%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'80%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'100%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                        <Skeleton width={'50%'} height={40} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                    </div>
                    :
                    <div>
                        <center>
                            <AtoZTitle>{title} - {page}</AtoZTitle>
                        </center>
                        {layoutMode.map((item, i) => {
                            return (
                                <div key={i}>
                                    <center>
                                        {
                                            {
                                                //inline switch function
                                                //add new mode here
                                                1: <LayoutMode1 order={i} />,
                                                2: <LayoutMode2 order={i} />,
                                                0: <DefaultLayoutMode order={i} />
                                            }[item]
                                        }
                                    </center>
                                </div>
                            )
                        })}
                    </div>
                }
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <AtoZButtonDiv>
                        <FacebookShareButton
                            url={process.env.REACT_APP_URL + "AtoZ/" + page}
                            quote={page}
                        >
                            <FacebookIcon size={40} round style={{ marginRight: '10px' }} />
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={process.env.REACT_APP_URL + "AtoZ/" + page}
                            title={page}
                        >
                            <TwitterIcon size={40} round style={{ marginRight: '10px' }} />
                        </TwitterShareButton>
                        <LineShareButton
                            url={process.env.REACT_APP_URL + "AtoZ/" + page}
                            title={page}
                        >
                            <LineIcon size={40} round style={{ marginRight: '10px' }} />
                        </LineShareButton>
                        <WhatsappShareButton
                            url={process.env.REACT_APP_URL + "AtoZ/" + page}
                            title={page}
                        >
                            <WhatsappIcon size={40} round style={{ marginRight: '10px' }} />
                        </WhatsappShareButton>
                        <CopyToClipboard
                            text={process.env.REACT_APP_URL + "AtoZ/" + page}
                        >
                            <img
                                onClick={handleClickOpen}
                                src={url_icon}
                                style={{ borderRadius: '50%', width: '40px', cursor: 'pointer' }}
                                alt="url icon"
                            />
                        </CopyToClipboard>
                        <div
                            style={{
                                display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20
                            }}>
                            <MenuButton
                                onClick={openTooltip}
                            >
                                {t('menuDownloadApp')}
                            </MenuButton>
                        </div>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Copied to the clipboard!"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    The following url is copied to the clipboard:
                                    <br />
                                    <u>{process.env.REACT_APP_URL + "AtoZ/" + page}</u>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </AtoZButtonDiv>
                </div>
            </AtoZPageDiv>
            {/* </AtoZFlexDiv> */}
            <PopupModal Ref={ref} />
            <div style={{ marginBottom: '100px' }}></div>
            <Footer />
        </div >
    );
}

export default AtozPage;

/*
    useEffect(() => {
                db.collection("atoz")
                    .orderBy("category")
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.docs.forEach((doc) => {
                            if (doc.data()['validateFlag']) {
                                setOptionList((optionList) => [
                                    ...optionList,
                                    {
                                        option_id: doc.id,
                                        option_title_chi: doc.data()['title_chi'],
                                        option_title: doc.data()['title'],
                                        option_content: doc.data()['content'],
                                        option_category: doc.data()['category'],
                                        option_metaDescription: doc.data()['metaDescription'],
                                        option_keywords: doc.data()['keywords'],
                                        option_image: doc.data()['image'],
                                    }
                                ]);
                            }
                        });
                    });
    }, []);
*/

{/* <svg
                style={{ backgroundColor: 'white', zIndex: '3', position: 'relative' }}
                width="100%"
                viewBox="0 0 3840 259"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>Path</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
                        id="Path"
                        fill="#f3f3f3"
                    ></path>
                </g>
            </svg> */}