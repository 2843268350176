import React, { useState, useEffect, useRef } from 'react';
//import ActivityCard from '../components/ActivityCard';
//import ActivitySearchBox from '../components/ActivitySearchBox';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
//import Demo from '../components/Demo';
//import BrowseArticles from '../components/BrowseArticles';
//import ArticleBanner from '../components/ArticleBanner';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import img from '../images/chillful/event_1.jpg';
//import img_article_1 from '../images/articles/article1.jpg';
//import img_article_2 from '../images/articles/article2.jpg';
//import img_article_3 from '../images/articles/article3.jpg';
//import img_article_4 from '../images/articles/article4.jpg';
import url_icon from '../images/articles/url.png';
//import back_icon from '../images/articles/backtoarticles.png';
//import img_event_1 from '../images/chillful/event_1.jpg';
//import banner_video from '../images/articles/banner_video.jpg';
//import pattern_background from '../images/general/pattern_background.jpg';
//import demo1 from '../images/mp3/demo1.mp3';
//import { storage, db } from '../firebase';
import { db } from '../firebase.js';
//import Slider from 'infinite-react-carousel';
//import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as HtmlToReact from 'html-to-react';
import { useParams } from 'react-router-dom';
import PopupModal from '../components/PopupModal';
//import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Skeleton from '@material-ui/lab/Skeleton';
//import { Helmet, HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
//import Skeleton from 'react-loading-skeleton';
//import Footer from '../components/Footer';
//import './Article.css';

import {
  //BrowserRouter as Router,
  Link,
} from "react-router-dom";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
} from "react-share";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import { to } from '@react-spring/core';

const MenuButton = styled.button`
  padding: 10px 20px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;
/*
const ArticleTable = styled.table`
  width: 100%;
  margin-top: -30px;
  zindex: 2;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const ArticleTable768 = styled.table`
  width: 100%;
  margin-top: -30px;
  zindex: 2;
  position: relative;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;
const BannerBtn = styled.button`
  padding: 20px 35px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;
const BannerBtn2 = styled.button`
  padding: 30px 33px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;
const BannerBtn3 = styled.button`
  padding: 5px 17px 6px 17px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-left: 20px;
  margin-top: 3px;
`;
const BannerVideo = styled.div`
  background-image: url(${banner_video});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 550px;
  margin-top: -15px;
  border-radius: 10px;
`;
const PatternBackground = styled.div`
  background-image: url(${pattern_background});
  background-size: cover;
  width: 100%;
  height: 500px;
  margin-top: -15px;
  border-radius: 10px;
`;
const ArticleCardDiv = styled.div`
  width: calc(100% - 10px);
  height: 300px;
  background-position: center;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;
const ArticleCardDivGrey = styled.div`
  width: calc(40% - 13px);
  height: 300px;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  position: absolute;
  margin-top: -310px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;
*/
const ArticleBannerContent = styled.div`
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 100px;
  padding-bottom: 60px;
  background-color: #ffffff;
  line-height: 30px;
`;
const ArticleBannerContentHeader = styled.h1`
  line-height: 38px;
  width: 60%;
  margin-bottom: 40px;
  @media only screen and (max-width: 1200px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
/*
const BrowseArticlesTitle = styled.h1`
  font-size: 60px;
  color: #555;
  margin-top: -57px;
  margin-left: 10%;
  z-index: 0;
  position: relative;
  line-height: 90px;
  margin-bottom: 2%;
`;
*/
const ArticleBannerContentDiv = styled.div`
width: 64%;
margin-left: 18%;
@media only screen and (max-width: 1200px) {
  width: 90%;
  margin-left: 5%;
}
@media only screen and (max-width: 768px) {
  width: 100%;
  margin-left: 0;
}
`;
const SuggestedArticleBannerContentDiv = styled.li`
margin-left: 25%;
margin-bottom: 15px;
@media only screen and (max-width: 1200px) {
  margin-left: 12%;
}
@media only screen and (max-width: 768px) {
  margin-left: 10px;
}
`;
const SuggestedArticleBannerTitleDiv = styled.h1`
margin-left: 25%;
margin-bottom: 0;
@media only screen and (max-width: 1200px) {
  margin-left: 12%;
}
@media only screen and (max-width: 768px) {
  margin-left: 10px;
}
`;
const ArticleContentDiv = styled.div`
  margin-left: 10%;
  margin-right: 10%;
@media only screen and (max-width: 1200px) {
  margin-left: 8%;
  margin-right: 8%;
}
@media only screen and (max-width: 768px) {
  margin-left: 10px;
  margin-right: 10px;
}
`;
const ArticleImageDiv = styled.div`
  height: 400px;
  margin-left: 15%;
  margin-right: 15%;
  align-item: center;
  display: flex;
  border-radius: 25px;
  margin-bottom: 30px;
  @media only screen and (max-width: 1200px) {
    margin-left: 6%;
    margin-right: 6%;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const ArticleImageGreyDiv = styled.div`
  height: 400px;
  margin-left: 15%;
  margin-right: 15%;
  align-item: center;
  display: flex;
  border-radius: 25px;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1200px) {
    margin-left: 6%;
    margin-right: 6%;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const SkeletonDiv = styled.div`
  margin-left: 15%;
  margin-right: 15%;
  @media only screen and (max-width: 1200px) {
    margin-left: 6%;
    margin-right: 6%;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

// eslint-disable-next-line require-jsdoc
function Articles() {
  const { p } = useParams();
  //const { t } = useTranslation();
  const { t, i18n } = useTranslation();
  //const [defaultBanner, setDefaultBanner] = useState(p > 0 ? p : 2);
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  /*
    function toDownloadAppIOS() {
      window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
    }
    function toDownloadAppAndroid() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
    }
  */
  const [articlesList, setArticlesList] = useState([]);
  const [suggestedArticlesList, setSuggestedArticlesList] = useState([]);
  const [articlesContent, setArticlesContent] = useState("");
  const htmlToReactParser = new HtmlToReact.Parser();
  const parsecontent = htmlToReactParser.parse(articlesContent);
  const [articlesImage, setArticlesImage] = useState("");
  const [articlesTags, setArticlesTags] = useState([]);
  //const [articleNumber, setArticleNumber] = useState(8);
  const [loading, setLoading] = useState(true);
  const [loadingSuggested, setLoadingSuggested] = useState(true);
  const [loadingP, setLoadingP] = useState(true);
  const [metaDescription, setMetaDescription] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var count = 3;

  function compareTagsNo(tagsA, tagsB) {
    let sameTagsList = tagsA.filter(value => tagsB.includes(value));
    return sameTagsList.length;
  }

  function sortsuggestedArticleListByTags() {
    let temp = suggestedArticlesList;
    temp.sort(function (x, y) {
      let a = x.article_tags;
      let b = y.article_tags;
      if (compareTagsNo(articlesTags, a) > compareTagsNo(articlesTags, b)) {
        return -1;
      }
      if (compareTagsNo(articlesTags, a) < compareTagsNo(articlesTags, b)) {
        return 1;
      }
      return 0;
    });
    setSuggestedArticlesList(temp);
  }

  useEffect(() => {
    if (p != "" && p != undefined) {
      db.collection("articles")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.docs.forEach((doc) => {
            if (doc.data()['validateFlag']) {
              setSuggestedArticlesList(
                (suggestedArticlesList) => [
                  ...suggestedArticlesList,
                  {
                    article_title: doc.data()['title'],
                    article_tags: doc.data()['tags'],
                  }
                ]);
            }
          });
        })
        .then(() => { setLoadingSuggested(false); });
    }
  }, []);

  useEffect(() => {
    $('#article_11').hover(
      function () {
        $('#article_11_hover_box').css('transform', 'translate(18px, -18px)');
      },
      function () {
        $('#article_11_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_11_768').hover(
      function () {
        $('#article_11_768_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_11_768_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_21').hover(
      function () {
        $('#article_21_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_21_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_21_768').hover(
      function () {
        $('#article_21_768_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_21_768_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
  }, []);

  const Content = () => (
    <div>
      <ArticleBannerContent>
        <ArticleContentDiv>
          {sortsuggestedArticleListByTags()}
          {
            (loadingP) ?
              <div>
                <SkeletonDiv>
                  <Skeleton variant='text' height={670} animation="wave"
                    style={{ marginTop: "-220px", borderRadius: "25px", marginBottom: "-60px" }} />
                </SkeletonDiv>
                <center>
                  <Skeleton width={'40%'} height={60} animation="wave" />
                  <br />
                </center>
              </div>
              :
              <div>
                <ArticleImageDiv style={{
                  background: `url(${articlesImage}) no-repeat center`,
                  marginTop: "-60px", marginBottom: "50px"
                }} />
                <ArticleImageGreyDiv style={{
                  marginTop: "-450px"
                }} />
                <center>
                  <ArticleBannerContentHeader>{p}</ArticleBannerContentHeader>
                  <br />
                </center>
              </div>
          }
          <ArticleBannerContentDiv>
            {
              parsecontent
              ||
              <div>
                <Skeleton width={'20%'} height={50} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'80%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'100%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'50%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'20%'} height={50} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'80%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'100%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'50%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'20%'} height={50} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'80%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'100%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <Skeleton width={'50%'} animation="wave" style={{ marginTop: "0px", marginLeft: '0%' }} />
                <div style={{ height: '50px' }} />
              </div>
            }
            <br />
            <br />
            <FacebookShareButton
              url={process.env.REACT_APP_URL + "Article/" + p}
              quote={p}
            >
              <FacebookIcon size={40} round style={{ marginRight: '10px' }} />
            </FacebookShareButton>
            <TwitterShareButton
              url={process.env.REACT_APP_URL + "Article/" + p}
              title={p}
            >
              <TwitterIcon size={40} round style={{ marginRight: '10px' }} />
            </TwitterShareButton>
            <LineShareButton
              url={process.env.REACT_APP_URL + "Article/" + p}
              title={p}
            >
              <LineIcon size={40} round style={{ marginRight: '10px' }} />
            </LineShareButton>
            <WhatsappShareButton
              url={process.env.REACT_APP_URL + "Article/" + p}
              title={p}
            >
              <WhatsappIcon size={40} round style={{ marginRight: '10px' }} />
            </WhatsappShareButton>
            <CopyToClipboard
              text={process.env.REACT_APP_URL + "Article/" + p}
            >
              <img
                onClick={handleClickOpen}
                src={url_icon}
                style={{ borderRadius: '50%', width: '40px', cursor: 'pointer' }}
                alt="url icon"
              />
            </CopyToClipboard>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Copied to the clipboard!"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  The following url is copied to the clipboard:
                  <br />
                  <u>{process.env.REACT_APP_URL + "Article/" + p}</u>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <div
              style={{
                display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20
              }}>
              <MenuButton
                onClick={openTooltip}
              >
                {t('menuDownloadApp')}
              </MenuButton>
            </div>
          </ArticleBannerContentDiv>
        </ArticleContentDiv>
        {/* </div> */}
        <svg
          style={{ marginTop: '20px', marginBottom: '50px' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 260"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M-9.09494702e-13,0.5 L-9.09494702e-13,259.5 C543.289571,86.8333333 1184.99624,0.5 1925.12,0.5 C2499.39149,0.5 3137.68482,86.8333333 3840,259.5 L3840,0.5 L-9.09494702e-13,0.5 Z"
              id="Path"
              fill="#f3f3f3"
              transform="translate(1920.000000, 130.000000) scale(1, -1) translate(-1920.000000, -130.000000) "
            ></path>
          </g>
        </svg>
        <div style={{
          backgroundColor: '#f3f3f3',
          width: '100%'
        }}>
          <h1 style={{ marginTop: '-60px', marginLeft: '25%', height: '60px' }}></h1>
          <SuggestedArticleBannerTitleDiv>{t('moreArticles')} :</SuggestedArticleBannerTitleDiv>
          <br />
          {
            loadingSuggested ?
              <div style={{ marginBottom: '-87px' }}>
                <Skeleton width={'10%'} height={50} animation="wave" style={{ marginLeft: '25%', marginBottom: '0px' }} />
                <Skeleton width={'10%'} height={50} animation="wave" style={{ marginLeft: '25%', marginBottom: '0px' }} />
                <Skeleton width={'10%'} height={50} animation="wave" style={{ marginLeft: '25%', marginBottom: '25px' }} />
              </div>
              :
              <ul style={{ listStyle: 'none', marginBottom: '-77px' }}>
                {suggestedArticlesList
                  .map((item, i) => {
                    if (item.article_title != p && i < count) {
                      return (
                        <SuggestedArticleBannerContentDiv key={i}>
                          {
                            <Link
                              to={{
                                pathname: "/Articles/" + item.article_title
                              }}
                              style={{ textDecoration: 'none', color: 'black' }}
                            ><h3 style={{ fontSize: 16, fontWeight: 400, margin: 0, padding: 0 }}>{item.article_title}</h3>
                            </Link>
                          }
                        </SuggestedArticleBannerContentDiv>
                      )
                    }
                    else {
                      count++;
                    }
                  })
                }
              </ul>
          }
        </div>
      </ArticleBannerContent >
    </div >
  );

  useEffect(() => {
    db.collection("articles")
      .orderBy("order")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.docs.forEach((doc) => {
          if (doc.data()['validateFlag']) {
            setArticlesList((articlesList) => [
              ...articlesList,
              {
                id: doc.id,
                article_id: doc.data()['articleid'],
                article_title: doc.data()['title'],
                article_content: doc.data()['content'],
                article_tags: doc.data()['tags'],
                article_image: doc.data()['image'],
                article_order: doc.data()['order'],
                article_category: doc.data()['category'],
                article_metaDescription: doc.data()['metaDescription'],
              }
            ]);
          }
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (p !== "" && p !== undefined) {
      db.collection("articles")
        .where('title', '==', p)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.docs.forEach((doc) => {
            setArticlesContent(doc.data()['content']);
            setKeywords(doc.data()['tags']);
            setArticlesTags(doc.data()['tags']);
            setArticlesImage(doc.data()['image']);
            setMetaDescription(doc.data()['metaDescription']);
          });
        })
        .then(() => { setLoadingP(false); });
    }
  }, [p]);

  return (
    <div>
      {/* <title>{p}</title>
      <HelmetProvider>
        <Helmet>
          <title>{p}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={keywords} />
        </Helmet>
      </HelmetProvider> */}
      <Helmet>
        <title>{p}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <div style={{ width: '100%' }}>
        <Menu page={'article'} />
        <PopupModal Ref={ref} />
        <Content />
        <svg
          style={{ backgroundColor: 'white', zIndex: '3', position: 'relative' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 259"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
              id="Path"
              fill="#f3f3f3"
            ></path>
          </g>
        </svg>
        <Footer />
      </div >
    </div>
  );
}

export default Articles;
