import React, { useEffect } from 'react';
//import { BrowserRouter as Router, Switch, Route, Link, useParams } from 'react-router-dom';
//import { storage, db } from '../firebase';
import { db } from '../firebase.js';

function WriteDataTemperature({ match }) {
  useEffect(() => {
    let latitude = 0;
    let longitude = 0;

    if (match.params.data.split('|')[0] === 'Chek Lap Kok') {
      latitude = 22.3070722;
      longitude = 113.9025649;
    }
    if (match.params.data.split('|')[0] === 'Cheung Chau') {
      latitude = 22.2095717;
      longitude = 114.0117141;
    }
    if (match.params.data.split('|')[0] === 'Clear Water Bay') {
      latitude = 22.283775;
      longitude = 114.2854206;
    }
    if (match.params.data.split('|')[0] === 'HK Observatory') {
      latitude = 22.3070722;
      longitude = 113.9025649;
    }
    if (match.params.data.split('|')[0] === 'HK Park') {
      latitude = 22.2765149;
      longitude = 114.1573743;
    }
    if (match.params.data.split('|')[0] === 'Happy Valley') {
      latitude = 22.2699026;
      longitude = 114.1752625;
    }
    if (match.params.data.split('|')[0] === 'Kai Tak Runway Park') {
      latitude = 22.3048602;
      longitude = 114.2142191;
    }
    if (match.params.data.split('|')[0] === 'Kau Sai Chau') {
      latitude = 22.3615398;
      longitude = 114.2966324;
    }
    if (match.params.data.split('|')[0] === 'King\'s Park') {
      latitude = 22.311113;
      longitude = 114.1712442;
    }
    if (match.params.data.split('|')[0] === 'Kowloon City') {
      latitude = 22.3219813;
      longitude = 114.1718193;
    }
    if (match.params.data.split('|')[0] === 'Kwun Tong') {
      latitude = 22.3120221;
      longitude = 114.2149615;
    }
    if (match.params.data.split('|')[0] === 'Lau Fau Shan') {
      latitude = 22.4681912;
      longitude = 113.9757342;
    }
    if (match.params.data.split('|')[0] === 'Ngong Ping') {
      latitude = 22.2556674;
      longitude = 113.9009929;
    }
    if (match.params.data.split('|')[0] === 'Pak Tam Chung') {
      latitude = 22.3970731;
      longitude = 114.310814;
    }
    if (match.params.data.split('|')[0] === 'Peng Chau') {
      latitude = 22.2859634;
      longitude = 114.0369655;
    }
    if (match.params.data.split('|')[0] === 'Sai Kung') {
      latitude = 22.4081311;
      longitude = 114.2468077;
    }
    if (match.params.data.split('|')[0] === 'Sha Tin') {
      latitude = 22.3887767;
      longitude = 114.1820634;
    }
    if (match.params.data.split('|')[0] === 'Sham Shui Po') {
      latitude = 22.3290803;
      longitude = 114.1478785;
    }
    if (match.params.data.split('|')[0] === 'Shau Kei Wan') {
      latitude = 22.2787351;
      longitude = 114.2220546;
    }
    if (match.params.data.split('|')[0] === 'Shek Kong') {
      latitude = 22.4296377;
      longitude = 114.0681678;
    }
    if (match.params.data.split('|')[0] === 'Sheung Shui') {
      latitude = 22.5047435;
      longitude = 114.0925299;
    }
    if (match.params.data.split('|')[0] === 'Stanley') {
      latitude = 22.2213362;
      longitude = 114.2047033;
    }
    if (match.params.data.split('|')[0] === 'Ta Kwu Ling') {
      latitude = 22.5468575;
      longitude = 114.1467588;
    }
    if (match.params.data.split('|')[0] === 'Tai Mei Tuk') {
      latitude = 22.3923665;
      longitude = 113.9424615;
    }
    if (match.params.data.split('|')[0] === 'Tai Mei Tuk') {
      latitude = 22.4734478;
      longitude = 114.2312952;
    }
    if (match.params.data.split('|')[0] === 'Tai Mo Shan') {
      latitude = 22.4088458;
      longitude = 114.1291951;
    }
    if (match.params.data.split('|')[0] === 'Tai Po') {
      latitude = 22.4459487;
      longitude = 114.1462521;
    }
    if (match.params.data.split('|')[0] === 'Tate\'s Cairn') {
      latitude = 22.3578898;
      longitude = 114.2152083;
    }
    if (match.params.data.split('|')[0] === 'The Peak') {
      latitude = 22.2702624;
      longitude = 114.1414812;
    }
    if (match.params.data.split('|')[0] === 'Tseung Kwan O') {
      latitude = 22.3076328;
      longitude = 114.2559899;
    }
    if (match.params.data.split('|')[0] === 'Tsing Yi') {
      latitude = 22.3444758;
      longitude = 114.0808219;
    }
    if (match.params.data.split('|')[0] === 'Tsuen Wan Ho Koon') {
      latitude = 22.3823833;
      longitude = 114.1022378;
    }
    if (match.params.data.split('|')[0] === 'Tsuen Wan Shing Mun Valley') {
      latitude = 22.3748195;
      longitude = 114.1248637;
    }
    if (match.params.data.split('|')[0] === 'Tuen Mun') {
      latitude = 22.3954805;
      longitude = 113.9315835;
    }
    if (match.params.data.split('|')[0] === 'Waglan Island') {
      latitude = 22.1833525;
      longitude = 114.2912452;
    }
    if (match.params.data.split('|')[0] === 'Wetland Park') {
      latitude = 22.4700634;
      longitude = 114.0043944;
    }
    if (match.params.data.split('|')[0] === 'Wong Chuk Hang') {
      latitude = 22.2391821;
      longitude = 114.1612247;
    }
    if (match.params.data.split('|')[0] === 'Wong Tai Sin') {
      latitude = 22.3420553;
      longitude = 114.1898863;
    }
    if (match.params.data.split('|')[0] === 'Yuen Long Park') {
      latitude = 22.4417892;
      longitude = 114.0165956;
    }

    db.collection('dataTemperature')
      .doc(match.params.data.split('|')[0])
      .collection('history')
      .add({
        datetime: match.params.data.split('|')[1],
        createDatetime: new Date(),
        temperature: match.params.data.split('|')[2],
      });

    db.collection('dataTemperature')
      .doc(match.params.data.split('|')[0])
      .set({
        datetime: match.params.data.split('|')[1],
        lastUpdateDatetime: new Date(),
        temperature: match.params.data.split('|')[2],
        latitude: latitude,
        longitude: longitude,
      });
  }, []);

  return (
    <div>
      <h1>Automatic Weather Station: {match.params.data.split('|')[0]}</h1>
      <h1>Date time: {match.params.data.split('|')[1]}</h1>
      <h1>Air Temperature(degree Celsius): {match.params.data.split('|')[2]}</h1>
    </div>
  );
}

export default WriteDataTemperature;
