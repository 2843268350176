import React, { useEffect } from 'react';

import { isAndroid } from 'react-device-detect';

function DownloadManatee() {
    useEffect(() => {
        if (isAndroid) {
            window.location.href = 'https://bit.ly/manatee-app-android-NA';
        } else {
            window.location.href = 'https://bit.ly/manatee-app-ios-NA';
        }
    });

    return <div style={{ width: '100%' }}></div>;
}

export default DownloadManatee;
