import React, { useState, useEffect } from 'react';
import { db } from '../firebase.js';

function Marketing() {
  const [userinfo, setUserinfo] = useState([]);

  useEffect(() => {
    // to get the information for banner
    db.collection('users')
      .orderBy('meditationTime', 'desc')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.docs.forEach((doc) => {
          setUserinfo((userinfo) => [
            ...userinfo,
            {
              nickname: doc.data()['nickname'],
              meditationTime: doc.data()['meditationTime'],
            },
          ]);
        });
      });
  }, []);

  return (
    <div>
      <table>
        <tbody>
          {userinfo.map((data) => (
            <tr>
              <td>
                <p>{data.nickname}</p>
              </td>
              <td>
                <p>{data.meditationTime}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Marketing;
