import React, { useRef, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import img_article_1 from '../images/chillful/intro1.jpg';
import img_article_2 from '../images/chillful/intro2.jpg';
import img_article_3 from '../images/chillful/intro3.jpg';
import img_article_4 from '../images/chillful/intro4.jpg';
import PopupModal from '../components/PopupModal';

import {
  isDesktop,
  isIOS
} from "react-device-detect";
/*
const ChillfulActivityListStyle2 = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  @media only screen and (max-width: 1300px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
*/
const ArticleTable = styled.table`
  width: 100%;
  margin-top: -45px;
  zindex: 2;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ArticleTable768 = styled.table`
  width: 100%;
  margin-top: -45px;
  zindex: 2;
  position: relative;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const BannerBtn = styled.button`
  padding: 20px 35px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 30px;
`;
/*
const ArticleTitle = styled.h1`
  font-size: 60px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;
*/
const BannerBtn3 = styled.button`
  padding: 0px 7px 1px 7px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-left: 20px;
`;

const ArticleCardDiv1 = styled.div`
  width: calc(100% - 10px);
  height: 350px;
  background-image: url(${img_article_1});
  background-position: center;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;
const ArticleCardDiv2 = styled.div`
  width: calc(100% - 10px);
  height: 350px;
  background-image: url(${img_article_2});
  background-position: center;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;
const ArticleCardDiv3 = styled.div`
  width: calc(100% - 10px);
  height: 350px;
  background-image: url(${img_article_3});
  background-position: center;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;
const ArticleCardDiv4 = styled.div`
  width: calc(100% - 10px);
  height: 350px;
  background-image: url(${img_article_4});
  background-position: center;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;
const ArticleCardDivGrey = styled.div`
  width: calc(50% - 13px);
  height: 350px;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  position: absolute;
  margin-top: -360px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

function BrowseArticles({ page }) {

  useEffect(() => {
    $('#article_1').hover(
      function () {
        $('#article_1_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_1_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_2').hover(
      function () {
        $('#article_2_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_2_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_3').hover(
      function () {
        $('#article_3_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_3_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_4').hover(
      function () {
        $('#article_4_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_4_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_1_768').hover(
      function () {
        $('#article_1_768_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_1_768_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_2_768').hover(
      function () {
        $('#article_2_768_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_2_768_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_3_768').hover(
      function () {
        $('#article_3_768_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_3_768_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
    $('#article_4_768').hover(
      function () {
        $('#article_4_768_hover_box').css('transform', 'translate(8px, -8px)');
      },
      function () {
        $('#article_4_768_hover_box').css('transform', 'translate(0px, 0px)');
      },
    );
  }, []);
  /*
    function comingSoon() {
      alert('Coming soon!');
    }
    function toApp() {
      window.location.href = '/app/';
    }
  */
  const ref = useRef();
  const openTooltip = () => ref.current.open();

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  // footer content
  return (
    <div>
      <PopupModal Ref={ref} />
      <div>
        <ArticleTable>
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>

          <tbody>
            <tr>
              <td>
                <ArticleCardDiv1></ArticleCardDiv1>
                <ArticleCardDivGrey id="article_1">
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/chillful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 1
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        瀏覽特色本土休閒活動，包括手工藝、食出韻味、瑜伽與冥想和戶外及運動
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_1_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        瀏覽特色本土休閒活動，包括手工藝、食出韻味、瑜伽與冥想和戶外及運動
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
              <td>
                <ArticleCardDiv2></ArticleCardDiv2>
                <ArticleCardDivGrey id="article_2">
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/chillful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 2
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        一鍵簡單報名
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_2_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        一鍵簡單報名
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
            </tr>
            <tr>
              <td>
                <ArticleCardDiv3></ArticleCardDiv3>
                <ArticleCardDivGrey id="article_3">
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/chillful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 3
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        接收精美材料包 (如適用)
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_3_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        接收精美材料包 (如適用)
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
              <td>
                <ArticleCardDiv4></ArticleCardDiv4>
                <ArticleCardDivGrey id="article_4">
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/chillful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 4
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        安在家中，享受視像小班教學或錄影課體驗
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_4_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        安在家中，享受視像小班教學或錄影課體驗
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
            </tr>
          </tbody>
        </ArticleTable>

        <ArticleTable768>
          <colgroup>
            <col style={{ width: '100%' }} />
          </colgroup>

          <tbody>
            <tr>
              <td>
                <ArticleCardDiv1></ArticleCardDiv1>
                <ArticleCardDivGrey id="article_1_768" style={{ width: 'calc( 100% - 13px )' }}>
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/mindful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 1
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        瀏覽特色本土休閒活動，包括手工藝、食出韻味、瑜伽與冥想和戶外及運動
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_1_768_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        瀏覽特色本土休閒活動，包括手工藝、食出韻味、瑜伽與冥想和戶外及運動
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
            </tr>
            <tr>
              <td>
                <ArticleCardDiv2></ArticleCardDiv2>
                <ArticleCardDivGrey id="article_2_768" style={{ width: 'calc( 100% - 13px )' }}>
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/mindful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 2
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        一鍵簡單報名
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_2_768_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        一鍵簡單報名
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
            </tr>
            <tr>
              <td>
                <ArticleCardDiv3></ArticleCardDiv3>
                <ArticleCardDivGrey id="article_3_768" style={{ width: 'calc( 100% - 13px )' }}>
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/mindful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 3
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        接收精美材料包 (如適用)
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_3_768_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        接收精美材料包 (如適用)
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
            </tr>
            <tr>
              <td>
                <ArticleCardDiv4></ArticleCardDiv4>
                <ArticleCardDivGrey id="article_4_768" style={{ width: 'calc( 100% - 13px )' }}>
                  <div
                    style={{ position: 'absolute', width: 'calc( 100% - 24px )', marginTop: '20px' }}
                  >
                    <div
                      style={{
                        float: 'right',
                        backgroundColor: '#FF9700',
                        padding: '2px 8px 4px 8px',
                        borderRadius: '50px',
                      }}
                    >
                      <img
                        style={{ width: '12px', marginBottom: '-2px' }}
                        src={require('../images/general/mindful_icon_white.png')}
                        alt="icon"
                      ></img>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '700',
                          marginLeft: '4px',
                        }}
                      >
                        STEP 4
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      width: 'calc( 100% - 100px )',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      zIndex: '3',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        安在家中，享受視像小班教學或錄影課體驗
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                  <div
                    id="article_4_768_hover_box"
                    style={{
                      transition: 'all 0.5s',
                      width: 'calc( 100% - 100px )',
                      position: 'absolute',
                      bottom: 20,
                      left: 20,
                      borderRadius: '10px',
                      backgroundColor: '#FF9700',
                    }}
                  >
                    <div style={{ padding: '15px 20px 5px 20px' }}>
                      <p style={{ color: '#333', fontSize: '18px', fontWeight: '700' }}>
                        安在家中，享受視像小班教學或錄影課體驗
                      </p>
                    </div>
                    <BannerBtn3 style={{ marginBottom: '15px' }}>
                      <img
                        style={{ width: '8px', marginLeft: '1px', marginTop: '0px' }}
                        src={require('../images/general/play_white.png')}
                        alt="icon"
                      ></img>
                    </BannerBtn3>
                  </div>
                </ArticleCardDivGrey>
              </td>
            </tr>
          </tbody>
        </ArticleTable768>
      </div>

      <div style={{ marginTop: '80px', position: 'relative' }}>
        <div
          style={{
            left: '50%',
            marginRight: '-50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <BannerBtn onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}>立即報名</BannerBtn>
        </div>
      </div>
    </div>
  );
}

export default BrowseArticles;
